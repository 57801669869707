

























































































































import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ava } from '@/AVA'
import MnemonicPhraseModal from '@/components/modals/MnemonicPhraseModal.vue'
import HdDerivationListModal from '@/components/modals/HdDerivationList/HdDerivationListModal.vue'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import Tooltip from '@/components/misc/Tooltip.vue'
import Spinner from '@/components/misc/Spinner.vue'
import Ellipsis from '@/components/misc/Ellipsis.vue'

import ExportKeys from '@/components/modals/ExportKeys.vue'
import PrivateKey from '@/components/modals/PrivateKey.vue'
import { WalletNameType, WalletType } from '@/js/wallets/types'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import { privateToPublic } from '@ethereumjs/util'
import MultisigOwnersModal from '@/components/modals/MultisigOwnersModal.vue'
import { MultisigWallet } from '@/js/wallets/MultisigWallet'

@Component({
    components: {
        MnemonicPhraseModal,
        HdDerivationListModal,
        MultisigOwnersModal,
        Spinner,
        Tooltip,
        ExportKeys,
        PrivateKey,
        Ellipsis,
    },
})
export default class KeyRow extends Vue {
    @Prop() wallet!: WalletType
    @Prop({ default: false }) is_default?: boolean

    isEditable = false
    customWalletName = this.walletName
    activating = false

    $refs!: {
        export_wallet: ExportKeys
        modal: MnemonicPhraseModal
        modal_hd: HdDerivationListModal
        modal_priv_key: PrivateKey
        editNameInput: HTMLInputElement
    }

    get allMultisigWalletsOwners() {
        return this.$store.state.wallets
            .filter((wallet: WalletType) => wallet.type === 'multisig')
            .flatMap((wallet: MultisigWallet) => wallet.keyData.owner.addresses)
            .map((address: string) => address.split('-')[1])
    }

    get isVolatile() {
        return this.$store.state.volatileWallets.includes(this.wallet)
    }

    get walletTitle() {
        return this.wallet.getBaseAddress()
    }

    get walletName() {
        return this.wallet.name
    }

    get walletType(): WalletNameType {
        return this.wallet.type
    }

    get hasMnemonic(): boolean {
        return (
            this.wallet.type === 'mnemonic' ||
            (this.wallet.type === 'singleton' && (this.wallet as SingletonWallet).getSeed() !== '')
        )
    }

    get isHDWallet() {
        return ['mnemonic', 'ledger'].includes(this.walletType)
    }

    get mnemonicPhrase(): MnemonicPhrase | null {
        if (!this.hasMnemonic) return null
        let wallet = this.wallet as MnemonicWallet | SingletonWallet
        return wallet.getMnemonicEncrypted()
    }

    get privateKey(): string | null {
        if (this.walletType !== 'singleton') return null
        let wallet = this.wallet as SingletonWallet
        return wallet.key
    }

    get privateKeyC(): string | null {
        if (this.walletType === 'ledger') return null
        let wallet = this.wallet as SingletonWallet | MnemonicWallet
        return wallet.ethKey
    }

    get publicKeyC(): string | null {
        if (this.walletType === 'ledger') return null
        let wallet = this.wallet as SingletonWallet | MnemonicWallet
        return '04' + privateToPublic(Buffer.from(wallet.ethKey, 'hex')).toString('hex')
    }

    get icon(): string {
        switch (this.walletType) {
            case 'mnemonic':
                return 'mdi-list-box-outline'
            case 'multisig':
                return 'mdi-account-group-outline'
            default:
                return 'mdi-shield-key-outline'
        }
    }

    get prefixPos(): number {
        return ava.getHRP().length + 3
    }

    onClickEdit() {
        this.isEditable = !this.isEditable
        if (!this.isEditable) {
            if (this.customWalletName === '') {
                this.customWalletName = this.walletName
                return
            } else if (this.customWalletName !== this.walletName) {
                this.wallet.name = this.customWalletName
                this.$store.state.volatileWallets.push(this.wallet)
            }
        } else {
            this.customWalletName = this.walletName
            this.$nextTick(() => this.$refs.editNameInput.focus())
        }
    }

    onCancel() {
        this.isEditable = false
        this.customWalletName = this.walletName
    }

    remove() {
        this.$emit('remove', this.wallet)
    }

    select() {
        this.activating = true
        setTimeout(() => this.$emit('select', this.wallet), 100)
    }

    showModal() {
        let modal = this.$refs.modal
        //@ts-ignore
        modal.open()
    }

    showPastAddresses() {
        let modal = this.$refs.modal_hd
        //@ts-ignore
        modal.open()
    }

    showExportModal() {
        //@ts-ignore
        this.$refs.export_wallet.open()
    }

    showKeyInfoModal() {
        //@ts-ignore
        this.$refs.modal_keys_info.open()
    }

    showMultisigOwnerModal() {
        //@ts-ignore
        this.$refs.modal_multisig.open()
    }
}






















































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import CopyText from '@/components/misc/CopyText.vue'
import QRModal from '@/components/modals/QRModal.vue'
import PaperWallet from '@/components/modals/PaperWallet/PaperWallet.vue'
import { WalletType, WalletNameType } from '@/js/wallets/types'
import Ellipsis from '@/components/misc/Ellipsis.vue'

import MnemonicWallet, {
    AVA_ACCOUNT_PATH,
    LEDGER_ETH_ACCOUNT_PATH,
} from '@/js/wallets/MnemonicWallet'
import { LedgerWallet } from '@/js/wallets/LedgerWallet'

import ChainSelect from '@/components/wallet/TopCards/AddressCard/ChainSelect.vue'
import { ChainIdType } from '@/constants'
import { ava } from '@/AVA'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'

@Component({
    components: {
        CopyText,
        Ellipsis,
        PaperWallet,
        QRModal,
        ChainSelect,
    },
})
export default class AddressCard extends Vue {
    chainNow: ChainIdType = 'X'

    $refs!: {
        qr_modal: QRModal
        print_modal: PaperWallet
        qr: HTMLCanvasElement
    }

    get addressLabel(): string {
        switch (this.chainNow) {
            default:
                return this.$t('top.address.title_x') as string
            case 'P':
                return this.$t('top.address.title_p') as string
            case 'C':
                return this.$t('top.address.title_c') as string
        }
    }

    get addressMsg(): string {
        switch (this.chainNow) {
            default:
                return this.getAddressMsgX()
            case 'P':
                return this.$t('top.address.desc_p') as string
            case 'C':
                return this.$t('top.address.desc_c') as string
        }
    }

    getAddressMsgX() {
        return this.$t('top.address.desc_x') as string
    }

    get prefixPos(): number {
        if (this.chainNow === 'C') return 0
        return ava.getHRP().length + 3
    }

    get walletType(): WalletNameType {
        let wallet = this.activeWallet
        if (
            !wallet ||
            (wallet.type === 'singleton' && (wallet as SingletonWallet).getMnemonic() !== '')
        )
            return 'mnemonic'
        return wallet.type
    }

    get activeWallet(): WalletType | null {
        return this.$store.state.activeWallet
    }
    get address() {
        let wallet = this.activeWallet
        if (!wallet) {
            return '-'
        }
        return wallet.getCurrentAddressAvm()
    }

    get addressPVM() {
        let wallet = this.activeWallet
        if (!wallet) {
            return '-'
        }

        return wallet.getCurrentAddressPlatform()
    }

    get addressEVM() {
        let wallet = this.activeWallet
        if (!wallet) {
            return '-'
        }

        return '0x' + wallet.getEvmAddress()
    }

    get activeAddress(): string {
        switch (this.chainNow) {
            case 'X':
                return this.address
            case 'P':
                return this.addressPVM
            case 'C':
                return this.addressEVM
        }
        return this.address
    }

    get activeIdx(): number {
        const wallet = this.activeWallet as MnemonicWallet
        const walletType = wallet.type

        if (walletType === 'singleton') return 0

        switch (this.chainNow) {
            case 'X':
                return wallet.getExternalActiveIndex()
            case 'P':
                return wallet.getPlatformActiveIndex()
            default:
                return 0
        }
    }

    viewQRModal() {
        // @ts-ignore
        this.$refs.qr_modal.open()
    }
    viewPrintModal() {
        let modal = this.$refs.print_modal
        // @ts-ignore
        modal.open()
    }

    async verifyLedgerAddress() {
        const wallet = this.activeWallet as LedgerWallet

        let hrp = ava.getHRP()

        switch (this.chainNow) {
            case 'X':
            case 'P':
                wallet.app.getWalletAddress(`${AVA_ACCOUNT_PATH}/0/${this.activeIdx}`, hrp)
                break
            case 'C':
                wallet.ethApp.getAddress(`${LEDGER_ETH_ACCOUNT_PATH}`)
        }
    }
}




































import 'reflect-metadata'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { BN } from '@c4tplatform/caminojs/dist'

// @ts-ignore
import { BigNumInput } from '@c4tplatform/vue_components'
import AvaAsset from '@/js/AvaAsset'
import { ICurrencyInputDropdownValue } from '@/components/wallet/transfer/types'
import { IWalletAssetsDict, priceDict } from '@/store/types'

import BalanceDropdown from '@/components/misc/BalancePopup/BalanceDropdown.vue'
import { ava } from '@/AVA'
import Big from 'big.js'
import { bnToBig } from '@/helpers/helper'
import { ChainIdType } from '@/constants'

@Component({
    components: {
        BigNumInput,
        BalanceDropdown,
    },
})
export default class CurrencyInputDropdown extends Vue {
    amount: BN = new BN(0)
    asset_now: AvaAsset = this.walletAssetsArray[0]

    @Prop({ default: () => [] }) disabled_assets!: AvaAsset[]
    @Prop({ default: '' }) initial!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop() chainId!: ChainIdType

    $refs!: {
        bigIn: BigNumInput
    }

    mounted() {
        if (this.isEmpty) return
        if (this.initial) {
            let initialAsset = this.walletAssetsDict[this.initial]
            this.drop_change(initialAsset)
        } else {
            this.drop_change(this.walletAssetsArray[0])
        }
    }

    @Watch('asset_now')
    drop_change(val: AvaAsset) {
        this.asset_now = val
        this.$refs.bigIn.clear()
        // this.amount_in(new BN(0))
        this.onchange()
    }

    get stepSize() {
        if (this.denomination > 3) {
            let stepNum = Math.pow(10, this.denomination - 2)
            return new BN(stepNum.toString())
        } else {
            let stepNum = Math.pow(10, this.denomination)
            return new BN(stepNum.toString())
        }
    }
    maxOut() {
        // @ts-ignore
        this.$refs.bigIn.maxout()
    }

    amount_in(val: BN) {
        this.amount = val
        this.onchange()
    }

    // onchange event for the Component
    @Emit('change')
    onchange(): ICurrencyInputDropdownValue {
        return {
            asset: this.asset_now,
            amount: this.amount,
        }
    }

    onfocus() {
        console.log('focus')
    }

    get amountUSD(): Big {
        let usdPrice = this.priceDict.usd
        let bigAmt = bnToBig(this.amount, this.denomination)
        let usdBig = bigAmt.times(usdPrice)
        return usdBig
    }

    get isEmpty(): boolean {
        if (this.walletAssetsArray.length === 0) {
            return true
        } else {
            return false
        }
    }

    get isAvax(): boolean {
        if (this.asset_now.id === this.avaxAsset?.id) return true
        return false
    }

    get display(): string {
        return ''
    }

    get placeholder(): string {
        if (this.isEmpty || !this.asset_now) return '0.00'
        let deno = this.asset_now.denomination
        let res = '0'
        if (deno > 2) {
            res = '0.00'
        }
        return res
    }

    get denomination(): number {
        if (!this.asset_now) return 0
        return this.asset_now.denomination
    }

    get walletAssetsArray(): AvaAsset[] {
        return this.$store.getters['Assets/walletAssetsArray']
    }

    get walletAssetsDict(): IWalletAssetsDict {
        return this.$store.getters['Assets/walletAssetsDict']
    }

    get avaxAsset(): AvaAsset | null {
        return this.$store.getters['Assets/AssetAVA']
    }

    get max_amount(): null | BN {
        if (!this.asset_now) return null
        if (!this.avaxAsset) return null

        let assetId = this.asset_now.id
        let balance = this.walletAssetsDict[assetId]
        const amount = this.chainId === 'P' ? balance.amountExtra : balance.amount

        let avaxId = this.avaxAsset.id

        // Max amount is BALANCE - FEE for native Asset
        if (assetId === avaxId) {
            let fee = ava.XChain().getTxFee()
            // console.log(fee);
            if (fee.gte(amount)) {
                return new BN(0)
            } else {
                return amount.sub(fee)
            }
        }

        if (amount.isZero()) return null
        return amount
    }

    get maxAmountBig(): Big {
        if (!this.max_amount) return Big(0)
        return bnToBig(this.max_amount, this.denomination)
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }
}





















import { Vue, Component, Prop } from 'vue-property-decorator'
import ERCNftToken from '@/js/ERCNftToken'
import ERCNftView from '@/components/misc/ERCNftView.vue'
import { iERCNftSelectInput } from '@/components/misc/EVMInputDropdown/types'
import { ERCNftBalance } from '@/store/modules/assets/modules/types'

@Component({
    components: { ERCNftView },
})
export default class ERCNftRow extends Vue {
    @Prop() token!: ERCNftToken

    // created() {
    //     this.getItems()
    // }

    get walletBalance(): ERCNftBalance[] {
        return this.$store.state.Assets.ERCNft.walletBalance[this.token.data.address] || []
    }

    get hasBalance(): boolean {
        return this.walletBalance.length > 0
    }

    selectToken(index: ERCNftBalance) {
        let data: iERCNftSelectInput = {
            id: index,
            token: this.token,
        }
        this.$emit('select', data)
    }
}

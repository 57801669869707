
































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import QRCode from 'qrcode'
import Modal from '@/components/modals/Modal.vue'

const ecdsa = require('elliptic').ec
const ec = new ecdsa('secp256k1')

@Component({
    components: {
        Modal,
    },
})
export default class PrivateKey extends Vue {
    @Prop({ default: '' }) privateKey!: string
    @Prop({ default: '' }) publicKey!: string

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()

        Vue.nextTick(() => {
            this.updateQR()
        })
    }

    get compressedPublicKey(): string {
        return Buffer.from(
            ec
                .keyFromPublic(Buffer.from(this.publicKey, 'hex'))
                .getPublic(true, 'hex')
                .padStart(66, '0'),
            'hex'
        ).toString('hex')
    }

    updateQR() {
        let canvas = this.$refs.qr as HTMLCanvasElement
        if (!canvas) return

        let size = canvas.clientWidth
        QRCode.toCanvas(
            canvas,
            this.privateKey,
            {
                scale: 6,
                color: {
                    dark: '#242729',
                    light: '#FFFD',
                },
                width: size,
            },
            function (error: any) {
                if (error) console.error(error)
            }
        )
    }
}

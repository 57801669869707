












import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import QRCode from 'qrcode'

@Component({
    components: {
        Modal,
    },
})
export default class MnemonicPhraseModal extends Vue {
    @Prop({ default: '' }) phrase!: MnemonicPhrase

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()

        Vue.nextTick(() => {
            this.updateQR()
        })
    }

    updateQR() {
        let canvas = this.$refs.qr as HTMLCanvasElement
        let size = canvas.clientWidth
        QRCode.toCanvas(
            canvas,
            this.phrase.getValue(),
            {
                scale: 6,
                color: {
                    dark: '#242729',
                    light: '#FFFD',
                },
                width: size,
            },
            function (error) {
                if (error) console.error(error)
            }
        )
    }
}











import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Modal from './Modal.vue'
import CopyText from '../misc/CopyText.vue'
import QRCode from 'qrcode'

@Component({
    components: {
        Modal,
        CopyText,
    },
})
export default class QRModal extends Vue {
    @Prop({ default: '-' }) address!: string

    @Watch('address', { immediate: true })
    onaddrchange(val: string) {
        if (val) {
            this.updateQR()
        }
    }

    open() {
        // @ts-ignore
        this.$refs.modal.open()

        Vue.nextTick(() => {
            this.updateQR()
        })
    }
    updateQR() {
        if (!this.address) return
        let canvas = this.$refs.qr
        QRCode.toCanvas(
            canvas,
            this.address,
            {
                scale: 6,
                color: {
                    dark: '#242729',
                    light: '#FFFD',
                },
            },
            function (error) {
                if (error) console.error(error)
            }
        )
    }
}

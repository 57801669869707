import { render, staticRenderFns } from "./CollectibleFamilyGroup.vue?vue&type=template&id=ab8ff0d8&scoped=true&"
import script from "./CollectibleFamilyGroup.vue?vue&type=script&lang=ts&"
export * from "./CollectibleFamilyGroup.vue?vue&type=script&lang=ts&"
import style0 from "./CollectibleFamilyGroup.vue?vue&type=style&index=0&id=ab8ff0d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab8ff0d8",
  null
  
)

export default component.exports
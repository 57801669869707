








































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { HotWalletType } from '@/js/wallets/types'
import { ExportWalletsInput } from '@/store/types'

@Component
export default class ExportWallet extends Vue {
    isLoading: boolean = false
    pass: string = ''
    passConfirm: string = ''
    err: string = ''

    @Prop() wallets!: HotWalletType[]
    @Prop({ default: true }) isDesc!: boolean

    get isValid(): boolean {
        return this.pass.length >= 9 && this.pass === this.passConfirm ? true : false
    }

    clear() {
        this.isLoading = false
        this.pass = ''
        this.passConfirm = ''
        this.err = ''
    }

    async download() {
        this.isLoading = true
        this.err = ''

        if (!this.wallets) {
            this.isLoading = false
            this.err = 'No wallet selected.'
            return
        }

        let input: ExportWalletsInput = {
            password: this.pass,
            wallets: this.wallets,
        }
        setTimeout(() => {
            this.$store.dispatch('exportWallets', input).then((res) => {
                this.isLoading = false
                this.pass = ''
                this.passConfirm = ''
                this.$store.dispatch('Notifications/add', {
                    title: 'Key File Export',
                    message: 'Your keys are downloaded.',
                })
                // @ts-ignore
                this.$emit('success')
            })
        }, 200)
    }
}

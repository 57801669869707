

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { WalletCore } from '@/js/wallets/WalletCore'

@Component({
    components: {},
})
export default class MultisigOwnerRow extends Vue {
    @Prop() wallet!: WalletCore
    @Prop() unlinkedAddress!: string

    get name() {
        return this.wallet?.name
    }

    get address(): string {
        return this.wallet ? this.wallet.getStaticAddress('X') : this.unlinkedAddress
    }

    get icon(): string {
        switch (this.wallet?.type) {
            case undefined:
                return 'mdi-link-off'
            case 'mnemonic':
                return 'mdi-list-box-outline'
            case 'multisig':
                return 'mdi-account-group-outline'
            default:
                return 'mdi-shield-key-outline'
        }
    }
}






















import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'

@Component({
    components: {
        Modal,
    },
})
export default class VerifyMnemonic extends Vue {
    isActive: boolean = false
    keysIn: string[] = []
    hiddenIndices: number[] = []
    err: string = ''
    title: string = ''

    @Prop() mnemonic?: MnemonicPhrase

    @Watch('mnemonic')
    onmnemonicchange(val: string) {
        this.init()
    }
    created() {
        this.init()
        this.title = `${this.$t('create.verifytitle')}`
    }

    init() {
        const wordsLen = 24
        this.keysIn = Array(wordsLen).join('.').split('.')

        // Hide 4 words
        let hideNum = 4
        let hidden: number[] = []

        while (hidden.length < hideNum) {
            let hideIndex = Math.floor(Math.random() * wordsLen)
            if (!hidden.includes(hideIndex)) {
                hidden.push(hideIndex)
            }
        }

        this.words.forEach((word, i) => {
            if (!hidden.includes(i)) {
                this.keysIn[i] = word
            }
        })

        this.hiddenIndices = hidden
    }

    get words() {
        return this.mnemonic ? this.mnemonic.getValue().split(' ') : []
    }

    open() {
        this.isActive = true
        // @ts-ignore
        this.$refs.modal.open()
    }

    close() {
        this.isActive = false
    }

    formCheck() {
        this.err = ''
        let userWords = this.keysIn

        for (var i = 0; i < userWords.length; i++) {
            let userWord = userWords[i].trim()
            let trueWord = this.words[i].trim()

            if (userWord.length === 0) {
                this.err = `Oops, looks like you forgot to fill number ${i + 1}`
                return false
            }

            if (userWord !== trueWord) {
                this.err = `The mnemonic phrase you entered for word ${
                    i + 1
                } not match the actual phrase.`
                return false
            }
        }

        return true
    }

    verify() {
        if (!this.formCheck()) return
        // @ts-ignore
        this.$refs.modal.close()
        this.$emit('complete')
    }
}

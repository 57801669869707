





















































































































import 'reflect-metadata'
import { Component, Vue, Watch } from 'vue-property-decorator'

import { ava } from '@/AVA'
import { ChainIdType, CrossChainsC, CrossChainsP, CrossChainsX } from '@/constants'
import Dropdown from '@/components/misc/Dropdown.vue'
import AvaxInput from '@/components/misc/AvaxInput.vue'
import Spinner from '@/components/misc/Spinner.vue'
import ChainCard from '@/components/wallet/ChainTransfer/ChainCard.vue'
import TxStateCard from '@/components/wallet/ChainTransfer/TxState.vue'
import ChainSwapForm from '@/components/wallet/ChainTransfer/Form.vue'
import { ChainSwapFormData, TxState } from '@/components/wallet/ChainTransfer/types'
import AvaAsset from '@/js/AvaAsset'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { WalletType } from '@/js/wallets/types'
import {
    BN,
    Big,
    avaxCtoX,
    bnToBig,
    bnToAvaxX,
    bnToBigAvaxX,
    bnToBigAvaxC,
    bigToBN,
} from '@/helpers/helper'
import {
    estimateExportGasFeeFromMockTx,
    estimateImportGasFeeFromMockTx,
    getBaseFeeRecommended,
} from '@/helpers/gas_helper'

import { SignatureError } from '@c4tplatform/caminojs/dist/common'

const IMPORT_DELAY = 5000 // in ms
const BALANCE_DELAY = 2000 // in ms

@Component({
    name: 'chain_transfer',
    components: {
        Spinner,
        Dropdown,
        AvaxInput,
        ChainCard,
        ChainSwapForm,
        TxStateCard,
    },
})
export default class ChainTransfer extends Vue {
    $refs!: {
        form: ChainSwapForm
    }
    sourceChain: ChainIdType = 'X'
    targetChain: ChainIdType = 'P'
    isLoading = false
    amt: BN = new BN(0)
    err: string = ''

    isImportErr = false
    isConfirm = false
    isSuccess = false

    formAmt: BN = new BN(0)

    baseFee: BN = new BN(0)

    // Transaction ids
    exportId: string = ''
    exportState: TxState = TxState.waiting
    exportStatus: string | null = null
    exportReason: string | null = null

    importId: string = ''
    importState: TxState = TxState.waiting
    importStatus: string | null = null
    importReason: string | null = null

    @Watch('sourceChain')
    @Watch('targetChain')
    onChainChange() {
        if (this.sourceChain === 'C' || this.targetChain === 'C') {
            this.updateBaseFee()
        }
    }

    created() {
        this.updateBaseFee()
    }

    get ava_asset(): AvaAsset | null {
        let ava = this.$store.getters['Assets/AssetAVA']
        return ava
    }

    get platformUnlocked(): BN {
        return this.$store.getters['Assets/walletPlatformBalanceUnlocked']
    }

    get platformLocked(): BN {
        return this.$store.getters['Assets/walletPlatformBalanceLocked']
    }

    get depositAndBond(): boolean {
        return this.$store.getters['Network/depositAndBond']
    }

    get avmUnlocked(): BN {
        if (!this.ava_asset) return new BN(0)
        return this.ava_asset.amount
    }

    get evmUnlocked(): BN {
        let balRaw = this.wallet.ethBalance
        return avaxCtoX(balRaw)
    }

    get balanceBN(): BN {
        if (this.sourceChain === 'P') {
            return this.platformUnlocked
        } else if (this.sourceChain === 'C') {
            return this.evmUnlocked
        } else {
            return this.avmUnlocked
        }
    }

    get balanceBig(): Big {
        return bnToBig(this.balanceBN, 9)
    }

    get formAmtText() {
        return bnToAvaxX(this.formAmt)
    }

    get fee(): Big {
        return this.exportFee.add(this.importFee)
    }

    get feeBN(): BN {
        return this.importFeeBN.add(this.exportFeeBN)
    }

    getFee(chain: ChainIdType, isExport: boolean): Big {
        if (chain === 'X') {
            return bnToBigAvaxX(ava.XChain().getTxFee())
        } else if (chain === 'P') {
            return bnToBigAvaxX(ava.PChain().getTxFee())
        } else {
            const fee = isExport
                ? estimateExportGasFeeFromMockTx(
                      this.targetChain as CrossChainsC,
                      this.amt,
                      this.wallet.getEvmAddress(),
                      this.wallet.getCurrentAddressPlatform()
                  )
                : estimateImportGasFeeFromMockTx(1, 1)

            const totFeeWei = this.baseFee.mul(new BN(fee))
            return bnToBigAvaxC(totFeeWei)
        }
    }

    get importFee(): Big {
        return this.getFee(this.targetChain, false)
    }

    /**
     * Returns the import fee in nNative
     */
    get importFeeBN(): BN {
        return bigToBN(this.importFee, 9)
    }

    get exportFee(): Big {
        return this.getFee(this.sourceChain, true)
    }

    get exportFeeBN(): BN {
        return bigToBN(this.exportFee, 9)
    }

    /**
     * The maximum amount that can be transferred in nNative
     */
    get maxAmt(): BN {
        let max = this.balanceBN.sub(this.feeBN)

        if (max.isNeg() || max.isZero()) {
            return new BN(0)
        } else {
            return max
        }
    }

    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }

    onFormChange(data: ChainSwapFormData) {
        this.amt = data.amount
        this.sourceChain = data.sourceChain
        this.targetChain = data.destinationChain
    }

    confirm() {
        this.formAmt = this.amt.clone()
        this.isConfirm = true
    }

    cancelConfirm() {
        this.isConfirm = false
        this.formAmt = new BN(0)
    }

    get wallet() {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet
    }

    async updateBaseFee() {
        this.baseFee = await getBaseFeeRecommended()
    }

    async submit() {
        this.err = ''
        this.isLoading = true
        this.isImportErr = false

        try {
            this.chainExport(this.formAmt, this.sourceChain, this.targetChain).catch((e) => {
                this.onerror(e)
            })
        } catch (err) {
            this.onerror(err)
        }
    }

    // Triggers export from chain
    // STEP 1
    async chainExport(amt: BN, sourceChain: ChainIdType, destinationChain: ChainIdType) {
        let wallet: WalletType = this.$store.state.activeWallet
        let exportTxId
        this.exportState = TxState.started

        try {
            switch (sourceChain) {
                case 'X':
                    exportTxId = await wallet.exportFromXChain(
                        amt,
                        destinationChain as CrossChainsX,
                        this.importFeeBN
                    )
                    break
                case 'P':
                    exportTxId = await wallet.exportFromPChain(
                        amt,
                        destinationChain as CrossChainsP,
                        this.importFeeBN
                    )
                    break
                case 'C':
                    exportTxId = await wallet.exportFromCChain(
                        amt,
                        destinationChain as CrossChainsC,
                        this.exportFeeBN
                    )
                    break
            }

            this.exportId = exportTxId
            this.waitExportStatus(exportTxId)
        } catch (e: any) {
            if (e instanceof SignatureError) {
                this.$store.dispatch('Notifications/add', {
                    type: 'info',
                    title: 'Multisignature',
                    message: e.message,
                })
                setTimeout(() => {
                    this.$store.dispatch('Assets/updateUTXOs')
                    this.$store.dispatch('Signavault/updateTransaction').then(() => {
                        this.$store.dispatch('History/updateMultisigTransactionHistory')
                    })
                }, 3000)
                this.exportState = TxState.success
                this.exportStatus = 'Recorded'
            } else {
                this.exportState = TxState.failed
                this.exportStatus = 'Failed'
                throw e
            }
        }
    }

    // STEP 2
    async waitExportStatus(txId: string, remainingTries = 15) {
        let status
        if (this.sourceChain === 'X') {
            status = await ava.XChain().getTxStatus(txId)
        } else if (this.sourceChain === 'P') {
            let resp = await ava.PChain().getTxStatus(txId)
            if (typeof resp === 'string') {
                status = resp
            } else {
                status = resp.status
                this.exportReason = resp.reason
            }
        } else {
            let resp = await ava.CChain().getAtomicTxStatus(txId)
            status = resp
        }
        this.exportStatus = status

        if (status === 'Unknown' || status === 'Processing') {
            // If out of tries
            if (remainingTries <= 0) {
                this.exportState = TxState.failed
                this.exportStatus = 'Timeout'
                return false
            }

            // if not confirmed ask again
            setTimeout(() => {
                this.waitExportStatus(txId, remainingTries - 1)
            }, 1000)
            return false
        } else if (status === 'Dropped') {
            // If dropped stop the process
            this.exportState = TxState.failed
            return false
        } else {
            // If success start import
            this.exportState = TxState.success

            // Because the API nodes are behind a load balancer we are waiting for all api nodes to update
            this.importState = TxState.started
            this.importStatus = 'Waiting'
            setTimeout(() => {
                this.chainImport()
            }, IMPORT_DELAY)
        }

        return true
    }

    // STEP 3
    async chainImport(canRetry = true) {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        let importTxId
        try {
            if (this.targetChain === 'P') {
                importTxId = await wallet.importToPlatformChain(this.sourceChain as CrossChainsP)
            } else if (this.targetChain === 'X') {
                importTxId = await wallet.importToXChain(this.sourceChain as CrossChainsX)
            } else {
                importTxId = await wallet.importToCChain(
                    this.sourceChain as CrossChainsC,
                    this.importFeeBN,
                    undefined,
                    this.exportId
                )
            }
        } catch (e) {
            // Retry import one more time
            if (canRetry) {
                setTimeout(() => {
                    this.chainImport(false)
                }, IMPORT_DELAY)
                return
            }
            this.onerror(e)
            this.onErrorImport(e)
            return
        }

        this.importId = importTxId
        this.importState = TxState.started

        this.waitImportStatus(importTxId)
    }

    // STEP 4
    async waitImportStatus(txId: string) {
        let status

        if (this.targetChain === 'X') {
            status = await ava.XChain().getTxStatus(txId)
        } else if (this.targetChain === 'P') {
            let resp = await ava.PChain().getTxStatus(txId)
            if (typeof resp === 'string') {
                status = resp
            } else {
                status = resp.status
            }
        } else {
            let resp = await ava.CChain().getAtomicTxStatus(txId)
            status = resp
        }

        this.importStatus = status

        if (status === 'Unknown' || status === 'Processing') {
            // if not confirmed ask again
            setTimeout(() => {
                this.waitImportStatus(txId)
            }, 1000)
            return false
        } else if (status === 'Dropped') {
            // If dropped stop the process
            this.importState = TxState.failed
            return false
        } else {
            // If success display success page
            this.importState = TxState.success
            this.onsuccess()
        }

        return true
    }

    onerror(err: any) {
        console.error(err)
        this.isLoading = false
        this.err = err
        this.$store.dispatch('Notifications/add', {
            type: 'error',
            title: 'Transfer Failed',
            message: err,
        })
    }

    onErrorImport(err: any) {
        this.importState = TxState.failed
        this.isImportErr = true
    }

    startAgain() {
        this.$refs.form.clear()

        this.err = ''
        this.isImportErr = false
        this.isConfirm = false
        this.isLoading = false
        this.isSuccess = false

        this.exportId = ''
        this.exportState = TxState.waiting
        this.exportStatus = null
        this.exportReason = null

        this.importId = ''
        this.importState = TxState.waiting
        this.importStatus = null
        this.importReason = null
    }

    onsuccess() {
        // Clear Form
        this.isSuccess = true
        this.$store.dispatch('Notifications/add', {
            type: 'success',
            title: 'Transfer Complete',
            message: 'Funds transferred between chains.',
        })

        setTimeout(() => {
            this.$store.dispatch('Assets/updateUTXOs')
            this.$store.dispatch('History/updateTransactionHistory')
        }, BALANCE_DELAY)
    }

    get canSubmit() {
        if (this.amt.eq(new BN(0))) {
            return false
        }

        if (this.amt.gt(this.maxAmt)) {
            return false
        }

        return true
    }
}

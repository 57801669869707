



































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import moment from 'moment'
import TxHistoryNftFamilyGroup from '@/components/SidePanels/TxHistoryNftFamilyGroup.vue'
import { Chain, ITransactionData } from '@/store/modules/history/types'
import { AvaNetwork } from '@/js/AvaNetwork'
import ImportExport from '@/components/SidePanels/History/ViewTypes/ImportExport.vue'
import BaseTx from '@/components/SidePanels/History/ViewTypes/BaseTx.vue'
import StakingTx from '@/components/SidePanels/History/ViewTypes/StakingTx.vue'
import RegisterNodeTx from '@/components/SidePanels/History/ViewTypes/RegisterNodeTx.vue'
import getMemoFromByteString from '@/services/history/utils'
import Ellipsis from '@/components/misc/Ellipsis.vue'

@Component({
    components: {
        Ellipsis,
        TxHistoryNftFamilyGroup,
    },
})
export default class TxHistoryRow extends Vue {
    @Prop() transaction!: ITransactionData

    get explorerUrl(): string | null {
        let network: AvaNetwork = this.$store.state.Network.selectedNetwork
        if (network.explorerSiteUrl) {
            let chains = this.$store.state.History.chains
            let alias = chains
                .find((elem: Chain) => elem.chainID === this.transaction.chainID)
                .chainAlias.toLowerCase()
            let url = `${network.explorerSiteUrl}/${alias}-chain/tx/${this.transaction.id}`
            return url
        }
        return null
    }

    get memo(): string | null {
        const memo = this.transaction.memo
        return getMemoFromByteString(memo)
    }

    get time() {
        return moment(this.transaction.timestamp)
    }

    get timeText(): string {
        let now = Date.now()
        let diff = now - new Date(this.transaction.timestamp).getTime()

        let dayMs = 1000 * 60 * 60 * 24

        if (diff > dayMs) {
            return this.time.format('MMM DD, YYYY')
        }
        return this.time.fromNow()
    }

    get viewComponent() {
        let type = this.transaction.type

        switch (type) {
            case 'export':
            case 'import':
            case 'pvm_export':
            case 'pvm_import':
                return ImportExport
            case 'add_delegator':
            case 'add_validator':
                return StakingTx
            case 'register_node':
                return RegisterNodeTx
            default:
                return BaseTx
        }
    }

    get multisigTx(): Object | undefined {
        return this.transaction.multisigStatus !== undefined
            ? { path: '/wallet/activity', query: { multisigTx: this.transaction.id } }
            : undefined
    }
}





















































import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'

import DepositOfferCard from '@/components/wallet/earn/DepositOfferCard.vue'
import DepositForm from '@/components/wallet/earn/DepositForm.vue'
import CreateOfferForm from '@/components/wallet/earn/CreateOfferForm.vue'
import { cleanAvaxBN } from '@/helpers/helper'

import { BN } from '@c4tplatform/caminojs/dist'
import { DepositOffer } from '@c4tplatform/caminojs/dist/apis/platformvm/interfaces'

@Component({
    components: {
        CreateOfferForm,
        DepositOfferCard,
        DepositForm,
    },
    data: () => ({
        depositOffer: undefined,
        createOffer: false,
    }),
})
export default class DepositOffers extends Vue {
    get platformOffers(): DepositOffer[] {
        return this.$store.getters['Platform/depositOffers'](true)
    }

    get hasOffers(): boolean {
        return this.platformOffers.length > 0
    }

    get maxDepositAmount(): BN {
        return this.$store.getters['Assets/walletPlatformBalanceUnlocked'].add(
            this.$store.getters['Assets/walletPlatformBalanceBonded']
        )
    }

    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }

    get canAddOffers(): boolean {
        return this.$store.getters['Platform/isOfferCreator']
    }

    cleanAvaxBN(val: BN): string {
        return cleanAvaxBN(val)
    }

    selectOffer(offer: DepositOffer): void {
        this.$data.depositOffer = offer
        if (offer === undefined) this.$data.createOffer = false
    }

    addOffer(): void {
        this.$data.createOffer = true
    }

    closeOffer(): void {
        this.$data.depositOffer = undefined
        this.$data.createOffer = false
    }
}




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { wordlists } from 'bip39'
// @ts-ignore
import { QrReader } from '@c4tplatform/vue_components'

@Component({
    components: {
        QrReader,
    },
})
export default class MnemonicInput extends Vue {
    @Prop() phrase!: string
    phraseArray = this.phrase.split(' ')

    isHidden: boolean = true
    wordNum: number = 24
    password: boolean = false

    async mounted() {
        if (!(window.getComputedStyle(this.$el) as any).webkitTextSecurity) {
            this.password = true
        }
    }

    onQRChange(val: string) {
        this.phraseArray = val.split(' ')
        this.$emit('replace', { value: val })
    }

    getClass(word: string): string {
        let ret = 'phrase_word'
        if (!(wordlists.EN.includes(word) || !word)) ret = ret + ' invalid_input'
        if (!this.password && this.isHidden) ret = ret + ' pass'
        return ret
    }

    onChange(i: number) {
        const next = this.phraseArray[i].at(-1) === ' '
        const corrected = this.phraseArray[i].trim().toLowerCase()
        const a = corrected.split(' ')
        if (a.length === 24) {
            this.$emit('update', { value: corrected, index: -1 })
            this.phraseArray = a
        } else {
            this.$emit('update', { value: a[0], index: i })
            if (next) {
                let next = document.getElementById('i' + (i + 2)) as HTMLInputElement
                if (next && next.focus) {
                    next.focus()
                    next.select()
                }
            }
            this.phraseArray[i] = a[0]
        }
    }
}

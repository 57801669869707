
































import { Component, Prop, Vue } from 'vue-property-decorator'
import Spinner from '@/components/misc/Spinner.vue'
import { TxState } from '@/components/wallet/ChainTransfer/types'

@Component({
    components: {
        Spinner,
    },
})
export default class TxStateCard extends Vue {
    @Prop() state!: TxState
    @Prop() status!: string
    @Prop() reason!: string
    @Prop() txId!: string
    @Prop({ default: true }) isExport?: boolean
}

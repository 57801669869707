



















import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
// @ts-ignore
import { QrInput } from '@c4tplatform/vue_components'
import Spinner from '@/components/misc/Spinner.vue'

@Component({
    components: {
        QrInput,
        Spinner,
    },
})
export default class AddKeyString extends Vue {
    privateKeyInput: string = ''
    canAdd: boolean = false
    error: string = ''
    isLoading: boolean = false

    validateQR(val: string) {
        if (this.privateKeyInput.length > 10) {
            this.canAdd = true
        } else if (this.privateKeyInput.length === 0) {
            this.error = ''
            this.canAdd = false
        } else {
            this.canAdd = false
        }
    }

    addKey() {
        this.isLoading = true
        this.error = ''

        setTimeout(async () => {
            try {
                await this.$store.dispatch('addWalletSingleton', { key: this.privateKeyInput })
                // @ts-ignore
                this.$emit('success')
                this.clear()
            } catch (e: any) {
                this.isLoading = false

                if (e.message.includes('already')) {
                    this.error = this.$t('keys.import_key_duplicate_err') as string
                } else {
                    this.error = this.$t('keys.import_key_err') as string
                }
            }
        }, 200)
    }

    clear() {
        this.isLoading = false
        this.privateKeyInput = ''
        this.canAdd = false
        this.error = ''
    }
}

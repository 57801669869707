




























import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import { MultisigWallet } from '@/js/wallets/MultisigWallet'
import MultisigOwnerRow from '@/components/wallet/manage/MultisigOwnerRow.vue'

@Component({
    components: {
        Modal,
        MultisigOwnerRow,
    },
})
export default class MultisigOwnersModal extends Vue {
    @Prop() wallet!: MultisigWallet

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
    }

    get wallets() {
        return this.wallet.wallets
    }

    get unlinkedOwners() {
        return this.wallet.getUnlinkedOwners()
    }

    get threshold() {
        return this.wallet.keyData.owner.threshold
    }

    get name() {
        return this.wallet.name
    }
}

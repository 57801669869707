
































import 'reflect-metadata'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { bnToBig, Big } from '@/helpers/helper'
//@ts-ignore
import { BigNumInput } from '@c4tplatform/vue_components'
import { BN } from '@c4tplatform/caminojs/dist'
import { priceDict } from '../../store/types'

@Component({
    components: {
        BigNumInput,
    },
})
export default class AvaxInput extends Vue {
    @Model('change', { type: Object }) readonly amount!: BN
    @Prop({
        default: null,
    })
    max?: BN | null
    @Prop() initial?: BN

    @Prop() balance?: Big | null
    @Prop() alias?: string
    @Prop() readonly?: boolean

    maxOut(ev: MouseEvent) {
        ev.preventDefault()
        ev.stopPropagation()
        //@ts-ignore
        this.$refs.amt_in.maxout()
    }

    amount_in(val: BN) {
        this.$emit('change', val)
    }

    get amountUSD(): Big {
        let usdPrice = this.priceDict.usd
        let amount = bnToBig(this.amount, 9)
        let usdBig = amount.times(usdPrice)
        return usdBig
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }

    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }
}


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AvaxInput from '@/components/misc/AvaxInput.vue'
import { BN } from '@c4tplatform/caminojs/dist'
import Big from 'big.js'
import { bnToBig } from '@/helpers/helper'
import { ChainIdType } from '@/constants'
import { ChainSwapFormData } from '@/components/wallet/ChainTransfer/types'
import { MultisigWallet } from '@/js/wallets/MultisigWallet'
import { AvaWalletCore } from '@/js/wallets/types'

const chainTypes: ChainIdType[] = ['X', 'P', 'C']
const chainNames = {
    X: 'X Chain',
    C: 'C Chain',
    P: 'P Chain',
}

@Component({
    components: {
        AvaxInput,
    },
})
export default class Form extends Vue {
    sourceChain: ChainIdType = 'X'
    targetChain: ChainIdType = 'P'
    amt: BN = new BN(0)

    @Prop() balance!: Big
    @Prop() maxAmt!: BN
    @Prop() isConfirm!: boolean

    clear() {
        this.amt = new BN(0)
        this.onChange()
    }

    get chainNames() {
        return chainNames
    }

    get formAmtText() {
        return bnToBig(this.amt, 9).toLocaleString()
    }

    get sourceOptions(): ChainIdType[] {
        if (this.isMultisigWallet) {
            return ['P']
        }
        if (!this.isEVMSupported) {
            return ['X', 'P']
        }

        let all = [...chainTypes]
        return all
    }

    get destinationOptions(): ChainIdType[] {
        if (this.isMultisigWallet) {
            return ['C']
        }
        return {
            X: ['P', 'C'],
            P: ['X', 'C'],
            C: ['X', 'P'],
        }[this.sourceChain] as ChainIdType[]
    }

    @Watch('destinationOptions')
    onDestinationsChange() {
        this.targetChain = this.destinationOptions[0]
        this.onChange()
    }

    get wallet(): AvaWalletCore {
        return this.$store.state.activeWallet
    }

    get isMultisigWallet() {
        return this.wallet instanceof MultisigWallet
    }

    get isEVMSupported() {
        return this.wallet.ethAddress
    }

    get nativeAssetSymbol() {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }

    onChangeSource(ev: any) {
        let val: ChainIdType = ev.target.value
        this.sourceChain = val
        this.onChange()
    }

    onChangeDestination(ev: any) {
        let val: ChainIdType = ev.target.value
        this.targetChain = val
        this.onChange()
    }

    onAmtChange() {
        this.onChange()
    }

    onChange() {
        if (!this.sourceOptions.includes(this.sourceChain)) this.sourceChain = this.sourceOptions[0]
        if (!this.destinationOptions.includes(this.targetChain))
            this.targetChain = this.destinationOptions[0]

        let data: ChainSwapFormData = {
            sourceChain: this.sourceChain,
            destinationChain: this.targetChain,
            amount: this.amt,
        }
        this.$emit('change', data)
    }

    mounted() {
        this.onChange()
    }
}










import 'reflect-metadata'
import { Vue, Component, Model } from 'vue-property-decorator'
import { CurrencyType } from '@/components/misc/CurrencySelect/types'

@Component
export default class CurrencySelect extends Vue {
    @Model('change', { type: String }) readonly currency!: CurrencyType
    setType(val: CurrencyType): void {
        this.$emit('change', val)
    }
    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }
}



































































































import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ava } from '@/AVA'
import { cleanAvaxBN } from '@/helpers/helper'
import { WalletHelper } from '@/helpers/wallet_helper'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'

import { BN } from '@c4tplatform/caminojs/dist'
import { KeyPair } from '@c4tplatform/caminojs/dist/apis/avm'
import { bufferToNodeIDString, privateKeyStringToBuffer } from '@c4tplatform/caminojs/dist/utils'
import { SignatureError } from '@c4tplatform/caminojs/dist/common'

@Component
export default class RegisterNode extends Vue {
    @Prop() isKycVerified!: boolean
    @Prop() isConsortiumMember!: boolean
    @Prop() minPlatformUnlocked!: BN
    @Prop() hasEnoughLockablePlatformBalance!: boolean

    nodePrivateKey = ''

    cleanAvaxBN(val: BN) {
        return cleanAvaxBN(val)
    }

    get wallet() {
        let wallet: SingletonWallet = this.$store.state.activeWallet
        return wallet
    }

    get staticAddress() {
        return this.wallet.getStaticAddress('P')
    }

    async registerNode() {
        try {
            let hrp = ava.getHRP()
            let keypair = new KeyPair(hrp, 'P')
            keypair.importKey(privateKeyStringToBuffer(this.nodePrivateKey.trim()))
            let nodeId = bufferToNodeIDString(keypair.getAddress())
            console.log(nodeId)
            const result = await WalletHelper.registerNodeTx(
                this.wallet,
                this.nodePrivateKey.trim(),
                undefined,
                nodeId,
                this.staticAddress
            )
            console.log(result)
            this.$emit('registered', nodeId)
            this.$store.dispatch('updateTransaction', {
                msgType: 'success',
                msgTitle: 'Transaction',
                msgText: 'Register Node Successful.',
            })
        } catch (error) {
            if (error instanceof SignatureError) {
                this.$store.dispatch('updateTransaction', {
                    onlyMultisig: true,
                    msgType: 'success',
                    msgTitle: 'Multisignature',
                    msgText: 'Transaction Recorded.',
                })
            } else {
                console.error(error)
                this.$store.dispatch('Notifications/add', {
                    type: 'error',
                    title: 'Registering Node Failed',
                    message: error,
                })
                return
            }
        }
    }
}

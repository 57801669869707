






import { Component, Prop, Vue } from 'vue-property-decorator'
import { UTF8Payload } from '@c4tplatform/caminojs/dist/utils'

@Component
export default class UtfPayloadView extends Vue {
    @Prop() payload!: UTF8Payload
    isText = false

    $refs!: {
        text: HTMLElement
        view: HTMLElement
    }
    mouseEnter() {
        this.showText()
    }

    mouseLeave() {
        this.isText = false
    }

    showText() {
        let pos: HTMLElement = this.$refs.view
        let rect = pos.getBoundingClientRect()

        this.$refs.text.style.top = rect.y + rect.height + 'px'
        this.$refs.text.style.left = rect.x + 'px'
        this.isText = true
    }

    get text() {
        return this.payload.getContent()
    }
}

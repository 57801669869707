





















import { Component, Vue } from 'vue-property-decorator'
import { GenericFormType, IGenericNft } from '@/components/wallet/studio/mint/types'

@Component
export default class GenericForm extends Vue {
    title = ''
    description = ''
    imgUrl = ''
    error = ''
    // radius = 15

    validate() {
        // if (!this.title) {
        //     this.error = 'You must set a title.'
        //     return false
        // }

        try {
            new URL(this.imgUrl)
        } catch (e) {
            this.error = this.$t('studio.mint.forms.generic.err1') as string
            // this.error = 'Not a valid Image URL.'
            return false
        }
        if (!this.imgUrl) {
            this.error = this.$t('studio.mint.forms.generic.err2') as string
            // this.error = 'You must set the image.'
            return false
        }

        if (this.imgUrl.length > 516) {
            this.error = this.$t('studio.mint.forms.generic.err3') as string
            // this.error = 'Image URL too long.'
            return false
        }

        // if (this.radius < 0 || this.radius > 100) {
        //     this.error = 'Invalid corner radius.'
        //     return false
        // }

        return true
    }

    onInput() {
        let msg: null | GenericFormType = null
        this.error = ''

        if (this.validate()) {
            let data: IGenericNft = {
                version: 1,
                type: 'generic',
                title: this.title,
                img: this.imgUrl,
                // radius: this.radius,
                desc: this.description,
            }

            msg = {
                data: {
                    avalanche: data,
                },
            }
        }
        this.$emit('onInput', msg)
    }
}






































import 'reflect-metadata'
import { Vue, Component, Watch } from 'vue-property-decorator'

import Modal from './Modal.vue'
import { web3 } from '@/evm'
import IERCNftAbi from '@/abi/IERC721MetaData.json'
import { ERCNftTokenInput } from '@/store/modules/assets/modules/types'
import ERCNftToken from '@/js/ERCNftToken'

@Component({
    components: {
        Modal,
    },
})
export default class AddERCNftTokenModal extends Vue {
    tokenAddress = ''
    name = ''
    symbol = ''
    canAdd = false
    err = ''

    @Watch('tokenAddress')
    async onAddressChange(val: string) {
        this.err = ''
        if (val === '') {
            this.clear()
            return
        }
        await this.validateAddress(val)
    }

    async validateAddress(val: string) {
        if (val === '') {
            this.err = ''
            return false
        }
        try {
            //@ts-ignore
            var tokenInst = new web3.eth.Contract(IERCNftAbi, val)
            let name = await tokenInst.methods.name().call()
            let symbol = await tokenInst.methods.symbol().call()

            this.symbol = symbol
            this.name = name

            this.canAdd = true
            return true
        } catch (e) {
            this.canAdd = false
            this.symbol = '-'
            this.name = '-'
            this.err = 'Invalid contract address.'
            return false
        }
    }

    clear() {
        this.tokenAddress = ''
        this.canAdd = false
        this.symbol = '-'
        this.name = '-'
        this.err = ''
    }

    async submit() {
        try {
            let data: ERCNftTokenInput = {
                type: undefined,
                address: web3.utils.toChecksumAddress(this.tokenAddress),
                name: this.name,
                symbol: this.symbol,
                chainId: this.$store.state.Assets.evmChainId,
                ercTokenIds: [],
            }

            let token: ERCNftToken = await this.$store.dispatch('Assets/ERCNft/addCustom', data)

            this.$store.dispatch('Notifications/add', {
                title: 'ERCNft Token Added',
                message: token.data.name,
            })
            this.close()
        } catch (e) {
            this.err = (e as Error).message
            console.error(e)
        }
    }

    beforeClose() {
        this.clear()
    }

    open() {
        // @ts-ignore
        this.$refs.modal.open()
    }

    close() {
        // @ts-ignore
        this.$refs.modal.close()
    }

    async removeToken(token: ERCNftToken) {
        await this.$store.dispatch('Assets/ERCNft/removeCustom', token)
    }

    get networkTokens(): ERCNftToken[] {
        return this.$store.getters['Assets/ERCNft/networkContractsCustom']
    }
}

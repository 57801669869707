

























































































































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import { DAY_MS, HOUR_MS, ZeroBN } from '@/constants'
import { cleanAvaxBN, formatDuration } from '@/helpers/helper'
import AvaAsset from '@/js/AvaAsset'
import DateForm from './DateForm.vue'
import AvaxInput from '@/components/misc/AvaxInput.vue'
import { WalletType } from '@/js/wallets/types'
import { DepositOffer, WalletHelper } from '@/helpers/wallet_helper'

import { BN } from '@c4tplatform/caminojs/dist'
import { SignatureError } from '@c4tplatform/caminojs/dist/common'

@Component({
    components: {
        DateForm,
        AvaxInput,
    },
})
export default class CreateOfferForm extends Vue {
    offer: DepositOffer = {
        upgradeVersion: this.sunrisePhase,
        id: '',
        interestRateNominator: ZeroBN,
        start: ZeroBN,
        end: ZeroBN,
        minAmount: new BN(1000000000),
        totalMaxAmount: ZeroBN,
        depositedAmount: ZeroBN,
        minDuration: 1,
        maxDuration: 86400,
        unlockPeriodDuration: 0,
        noRewardsPeriodDuration: 0,
        memo: '',
        flags: ZeroBN,
        // Upgrade Version 1
        totalMaxRewardAmount: ZeroBN,
        rewardedAmount: ZeroBN,
        ownerAddress: '',
    }

    get minDuration() {
        return 1 * HOUR_MS
    }

    get maxDuration() {
        return 1 * 365 * DAY_MS
    }

    get defaultDuration() {
        return 1 * DAY_MS
    }

    get ava_asset(): AvaAsset | null {
        let ava = this.$store.getters['Assets/AssetAVA']
        return ava
    }

    get sunrisePhase(): number {
        return this.$store.getters['Platform/getSunrisePhase']
    }

    get nativeAssetSymbol(): string {
        return this.ava_asset?.symbol ?? ''
    }

    get formValid(): boolean {
        if (this.offer.minDuration > this.offer.maxDuration) return false
        if (this.offer.maxDuration <= 0) return false
        if (this.offer.start > this.offer.end) return false
        return true
    }

    async submitCreateOffer(): Promise<void> {
        if (this.offer.ownerAddress === '') this.offer.ownerAddress = undefined
        const wallet: WalletType = this.$store.state.activeWallet
        try {
            const result = await WalletHelper.buildAddDepositOfferTx(wallet, this.offer)
            this.$store.dispatch('updateTransaction', {
                withDeposit: true,
                msgType: 'success',
                msgTitle: 'Transaction',
                msgText: `Create Offer Successful (TX: ${result})`,
            })
        } catch (error) {
            if (error instanceof SignatureError) {
                this.$store.dispatch('updateTransaction', {
                    onlyMultisig: true,
                    msgType: 'success',
                    msgTitle: 'Multisignature',
                    msgText: 'Transaction Recorded.',
                })
            } else {
                console.error(error)
                this.$store.dispatch('Notifications/add', {
                    type: 'error',
                    title: 'Create Offer Failed',
                    message: error,
                })
                return
            }
        }
        this.cancelCreateOffer()
    }

    cancelCreateOffer(): void {
        this.$emit('selectOffer')
    }

    cleanAvaxBN(val: BN): string {
        return cleanAvaxBN(val)
    }

    formatDuration(dur: number): string {
        return formatDuration(dur)
    }

    setStartDate(val: string) {
        this.offer.start = new BN(new Date(val).getTime() / 1000)
    }

    setEndDate(val: string) {
        this.offer.end = new BN(new Date(val).getTime() / 1000)
    }

    setInterestRate(ev: any) {
        this.offer.interestRateNominator = new BN(ev.target.value)
    }

    setFlags(ev: any) {
        this.offer.flags = new BN(ev.target.value)
    }
}

























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import { cleanAvaxBN } from '@/helpers/helper'
import ModalClaimReward from '@/components/modals/ClaimRewardModal.vue'
import { PlatformRewardTreasury } from '@/store/modules/platform/types'

import { BN } from '@c4tplatform/caminojs/dist'

@Component({
    components: {
        ModalClaimReward,
    },
})
export default class ClaimableRewardCard extends Vue {
    now: number = Date.now()
    intervalID: any = null
    claimDisabled: boolean = true

    @Prop() reward!: PlatformRewardTreasury

    $refs!: {
        modal_claim_reward: ModalClaimReward
    }

    get nativeAssetSymbol(): string {
        const asset = this.$store.getters['Assets/AssetAVA']
        return asset?.symbol ?? ''
    }

    get isClaimDisabled() {
        return !this.reward.amountToClaim.isZero()
    }

    get title() {
        return this.reward.type === 'validator' ? 'Validator Reward' : 'Deposit Reward'
    }

    cleanAvaxBN(val: BN): string {
        return cleanAvaxBN(val)
    }

    openModal() {
        this.$refs.modal_claim_reward.open()
    }
}

import { render, staticRenderFns } from "./NftCard.vue?vue&type=template&id=d2fa064a&scoped=true&"
import script from "./NftCard.vue?vue&type=script&lang=ts&"
export * from "./NftCard.vue?vue&type=script&lang=ts&"
import style0 from "./NftCard.vue?vue&type=style&index=0&id=d2fa064a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2fa064a",
  null
  
)

export default component.exports
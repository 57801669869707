























import { Vue, Component, Prop } from 'vue-property-decorator'
import { AvaNftFamily } from '../../../../../js/AvaNftFamily'
import { IWalletNftMintDict } from '@/store/types'
import { NFTTransferOutput, UTXO } from '@c4tplatform/caminojs/dist/apis/avm'
import NftPayloadView from '@/components/misc/NftPayloadView/NftPayloadView.vue'
import NftFamilyCardsPreview from '@/components/misc/NftFamilyCardsPreview.vue'
@Component({
    components: { NftFamilyCardsPreview, NftPayloadView },
})
export default class FamilyRow extends Vue {
    @Prop() family!: AvaNftFamily

    maxReviewItems = 14

    isHover = false
    mouseEnter() {
        this.isHover = true
    }
    mouseLeave() {
        this.isHover = false
    }

    get mintUtxos() {
        return this.nftMintDict[this.family.id]
    }

    get nftMintDict(): IWalletNftMintDict {
        // return this.$store.getters.walletNftMintDict
        return this.$store.getters['Assets/nftMintDict']
    }

    get nftUtxoDict(): IWalletNftMintDict {
        // return this.$store.getters.walletNftDict
        return this.$store.getters['Assets/walletNftDict']
    }

    // return utxos belonging to this family
    get nftUtxos(): UTXO[] {
        return this.nftUtxoDict[this.family.id] || []
    }

    // Return 1 of each group
    get groupUtxos() {
        let utxos = this.nftUtxos
        let ids: number[] = []

        let filtered = utxos.filter((utxo) => {
            let groupId = (utxo.getOutput() as NFTTransferOutput).getGroupID()

            if (ids.includes(groupId)) {
                return false
            } else {
                ids.push(groupId)
                return true
            }
        })

        // order by group id
        filtered.sort((a, b) => {
            let gA = (a.getOutput() as NFTTransferOutput).getGroupID()
            let gB = (b.getOutput() as NFTTransferOutput).getGroupID()
            return gA - gB
        })

        return filtered.slice(0, this.maxReviewItems)
    }

    select() {
        this.$emit('select', this.mintUtxos[0])
    }
}

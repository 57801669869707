




















import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'

import ClaimableRewardCard from '@/components/wallet/earn/ClaimableRewardCard.vue'
import DepositRewardCard from '@/components/wallet/earn/DepositRewardCard.vue'
import { PlatformRewards } from '@/store/modules/platform/types'

@Component({
    components: {
        ClaimableRewardCard,
        DepositRewardCard,
    },
})
export default class UserRewards extends Vue {
    get platformRewards(): PlatformRewards {
        return this.$store.state.Platform.rewards
    }

    get hasRewards(): boolean {
        return (
            this.platformRewards.depositRewards.length > 0 ||
            this.platformRewards.treasuryRewards.length > 0
        )
    }

    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }
}

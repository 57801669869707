





































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import AddKeyFile from '@/components/wallet/manage/AddKeyFile.vue'
import AddKeyString from '@/components/wallet/manage/AddKeyString.vue'
import AddMnemonic from '@/components/wallet/manage/AddMnemonic.vue'
import AddMultisigAlias from '@/components/wallet/manage/AddMultisigAlias.vue'

@Component({
    components: {
        Modal,
        AddKeyFile,
        AddKeyString,
        AddMnemonic,
        AddMultisigAlias,
    },
})
export default class ImportKeys extends Vue {
    title: string = ''
    selectedTab: string = ''

    $refs!: {
        modal: Modal
        keyfile: AddKeyFile
        keyString: AddKeyString
        mnemonic: AddMnemonic
        multisigAlias: AddMultisigAlias
    }
    created() {
        this.title = this.$t('keys.import_key_title') as string
    }

    open() {
        this.$refs.modal.open()
        this.selectedTab = 'private' // explicitly set v-model value for modal
    }

    beforeClose() {
        this.$refs.keyfile?.clear()
        this.$refs.keyString?.clear()
        this.$refs.mnemonic?.clear()
    }

    handleImportSuccess(event?: any) {
        this.$refs.modal.close()
        const payload = event ?? {
            title: this.$t('keys.import_key_success_title'),
            message: this.$t('keys.import_key_success_msg'),
        }
        this.$store.dispatch('Notifications/add', payload)
    }
}









import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITransactionData } from '@/store/modules/history/types'

@Component
export default class RegisterNodeTx extends Vue {
    @Prop() transaction!: ITransactionData
}










































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Big from 'big.js'

import { ava, bintools } from '@/AVA'
import AvaxInput from '@/components/misc/AvaxInput.vue'
import { WalletHelper } from '@/helpers/wallet_helper'
import AvaAsset from '@/js/AvaAsset'
import { WalletType } from '@/js/wallets/types'
import { RewardOwner } from '@/store/modules/platform/types'
import Modal from './Modal.vue'

import { BN } from '@c4tplatform/caminojs/dist'
import { OutputOwners } from '@c4tplatform/caminojs/dist/common'
import { ONEAVAX } from '@c4tplatform/caminojs/dist/utils'
import { SignatureError } from '@c4tplatform/caminojs/dist/common'

@Component({
    components: {
        AvaxInput,
        Modal,
    },
})
export default class ModalClaimReward extends Vue {
    @Prop() depositTxID?: string
    @Prop() amount!: BN
    @Prop() rewardOwner!: RewardOwner
    @Prop() validatorClaim!: boolean

    claimed: boolean = false
    confirmedClaimedAmount: string = ''
    amt: BN = this.amount

    $refs!: {
        modal: Modal
    }
    open() {
        this.$refs.modal.open()
    }
    close() {
        this.$refs.modal.close()
    }

    beforeClose() {
        this.confirmedClaimedAmount = ''
        this.$emit('beforeCloseModal', this.claimed)
        this.claimed = false
    }

    formattedAmount(val: BN): string {
        let big = Big(val.toString()).div(Big(ONEAVAX.toString()))
        return big.toLocaleString()
    }

    get feeAmt(): string {
        return this.formattedAmount(ava.PChain().getTxFee())
    }

    get claimableAmount(): string {
        return this.formattedAmount(this.amount)
    }

    get ava_asset(): AvaAsset | null {
        let ava = this.$store.getters['Assets/AssetAVA']
        return ava
    }

    get nativeAssetSymbol(): string {
        return this.ava_asset?.symbol ?? ''
    }

    async confirmClaim() {
        const wallet: WalletType = this.$store.state.activeWallet
        const hrp = ava.getHRP()
        const rewardOwner = new OutputOwners(
            this.rewardOwner.addresses.map((a) => bintools.stringToAddress(a, hrp)),
            this.rewardOwner.locktime,
            this.rewardOwner.threshold
        )

        try {
            const result = await WalletHelper.buildDepositClaimTx(
                wallet,
                this.depositTxID,
                rewardOwner,
                this.amt,
                this.validatorClaim
            )
            this.$store.dispatch('updateTransaction', {
                withDeposit: true,
                msgType: 'success',
                msgTitle: 'Transaction',
                msgText: `Claim Successful (TX: ${result})`,
            })
        } catch (error) {
            if (error instanceof SignatureError) {
                this.$store.dispatch('updateTransaction', {
                    onlyMultisig: true,
                    msgType: 'success',
                    msgTitle: 'Multisignature',
                    msgText: 'Transaction Recorded.',
                })
            } else {
                console.error(error)
                this.$store.dispatch('Notifications/add', {
                    type: 'error',
                    title: 'Claim Failed',
                    message: error,
                })
                this.claimed = false
                return
            }
        }
        this.confirmedClaimedAmount = this.formattedAmount(this.amt)
        this.claimed = true
    }
}

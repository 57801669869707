


































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import { cleanAvaxBN, formatDuration } from '@/helpers/helper'
import AvaAsset from '@/js/AvaAsset'

import { BN } from '@c4tplatform/caminojs/dist'
import { DepositOffer } from '@c4tplatform/caminojs/dist/apis/platformvm/interfaces'

@Component
export default class DepositOfferCard extends Vue {
    @Prop() offer!: DepositOffer
    @Prop() maxDepositAmount!: BN

    get rewardTitle() {
        return Buffer.from(this.offer.memo.replace('0x', ''), 'hex').toString()
    }

    get rewardPercent() {
        const interestRateBase = 365 * 24 * 60 * 60
        const interestRateDenominator = 1000000 * interestRateBase

        return (
            (this.offer.interestRateNominator.toNumber() * interestRateBase * 100) /
            interestRateDenominator
        )
    }

    get ava_asset(): AvaAsset | null {
        let ava = this.$store.getters['Assets/AssetAVA']
        return ava
    }

    get nativeAssetSymbol(): string {
        return this.ava_asset?.symbol ?? ''
    }

    get isDepositDisabled(): boolean {
        return this.maxDepositAmount.isZero()
    }

    get amountLimit(): { nominator: BN; amount: BN } {
        return this.offer.upgradeVersion === 0 || !this.offer.totalMaxAmount.isZero()
            ? { nominator: this.offer.depositedAmount, amount: this.offer.totalMaxAmount }
            : { nominator: this.offer.rewardedAmount, amount: this.offer.totalMaxRewardAmount }
    }

    get progress(): string {
        const amt = this.amountLimit
        return amt.amount.isZero()
            ? '0px'
            : amt.nominator.div(amt.amount).mul(new BN(100)).toString() + '%'
    }

    get progressText(): string {
        const amt = this.amountLimit
        return amt.amount.isZero()
            ? 'No Limit'
            : this.progress + '(' + cleanAvaxBN(amt.amount) + this.nativeAssetSymbol + ')'
    }

    emitOffer(): void {
        this.$emit('selectOffer', this.offer)
    }

    cleanAvaxBN(val: BN): string {
        return cleanAvaxBN(val)
    }

    formatDate(date: BN): string {
        const jsDate = new Date(date.toNumber() * 1000)
        return jsDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })
    }

    formatDuration(dur: number): string {
        return formatDuration(dur)
    }
}









import { Vue, Component, Prop } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import ERCNftView from '../misc/ERCNftView.vue'
import ERCNftToken from '@/js/ERCNftToken'
import { ERCNftBalance } from '@/store/modules/assets/modules/types'
@Component({
    components: { ERCNftView, Modal },
})
export default class ERCNftViewModal extends Vue {
    @Prop() token!: ERCNftToken
    @Prop() index!: ERCNftBalance

    $refs!: {
        modal: Modal
    }

    open() {
        this.$refs.modal.open()
    }
}








import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Ellipsis extends Vue {
    @Prop() text!: string
    @Prop() title!: string
    @Prop({ default: 0 }) prefixPos!: number
    @Prop() copy!: number

    shortText: string = ''
    charWidth: number = 9

    mounted() {
        this.charWidth =
            (parseInt(window.getComputedStyle(this.$refs.display as Element).fontSize) * 9) / 15
        this.calcShortText()
    }

    @Watch('text')
    calcShortText() {
        const displayW = (this.$refs.display as HTMLSpanElement).clientWidth
        const allowedChars = Math.trunc(displayW / this.charWidth)
        if (this.text.length > allowedChars) {
            const deleteChars = this.text.length - allowedChars + 2
            const deletePos =
                this.prefixPos + ((this.text.length - this.prefixPos - deleteChars) >> 1)
            this.shortText =
                this.text.slice(0, deletePos) + '..' + this.text.slice(deletePos + deleteChars)
            return
        }
        this.shortText = this.text
    }

    copyText() {
        navigator.clipboard.writeText(this.text).then(() => {
            this.$store.dispatch('Notifications/add', {
                title: this.title + ' Copied',
                message: 'Copied to clipboard.',
            })
        })
    }

    copyFullText() {
        if (this.copy === 1) this.copyText()
    }
}

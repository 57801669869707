














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Datetime } from 'vue-datetime'

@Component({
    components: {
        Datetime,
    },
})
export default class DateForm extends Vue {
    // timeNow = 0

    localStart = this.startDate
    localEnd = this.endDateMin

    @Prop() maxEndDate?: number
    @Prop() minDurationMs!: number
    @Prop() maxDurationMs!: number
    @Prop() defaultDurationMs!: number

    @Watch('localEnd')
    endChange(val: string) {
        this.setEndDate(val)

        let endTime = new Date(val).getTime()
        let minDateTime = new Date(this.endDateMin).getTime()

        if (endTime < minDateTime) {
            this.localEnd = this.endDateMin
        }
    }

    mounted() {
        this.localStart = this.startDate
        this.localEnd = this.defaultEndDate

        this.setEndDate(this.localEnd)
    }

    setEndDate(val: string) {
        this.$emit('change_end', val)
    }

    maxoutEndDate(ev: MouseEvent) {
        ev.preventDefault()
        this.localEnd = this.endDateMax
    }

    get startDate() {
        let now = Date.now() + 60
        now -= now % 60
        return new Date(now).toISOString()
    }

    get endDateMin() {
        let start = this.localStart
        let startDate = new Date(start)

        let end = startDate.getTime() + this.minDurationMs
        let endDate = new Date(end)
        return endDate.toISOString()
    }

    get endDateMax() {
        let start = this.localStart
        let startDate = new Date(start)

        let end = startDate.getTime() + this.maxDurationMs
        if (this.maxEndDate && end > this.maxEndDate) end = this.maxEndDate
        let endDate = new Date(end)

        return endDate.toISOString()
    }

    get defaultEndDate() {
        let start = this.localStart
        let startDate = new Date(start)

        let end = startDate.getTime() + this.defaultDurationMs
        let endDate = new Date(end)
        return endDate.toISOString()
    }
}

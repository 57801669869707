


































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import Erc20Token from '@/js/Erc20Token'
import Big from 'big.js'
import { WalletType } from '@/js/wallets/types'
import { bnToBig } from '@/helpers/helper'
import ERCNftToken from '@/js/ERCNftToken'
import ERCNftRow from '@/components/modals/EvmTokenSelect/ERCNftRow.vue'
import { iERCNftSelectInput } from '@/components/misc/EVMInputDropdown/types'

@Component({
    components: {
        ERCNftRow,
        Modal,
    },
})
export default class EVMTokenSelectModal extends Vue {
    $refs!: {
        modal: Modal
    }
    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
    }

    get tokens(): Erc20Token[] {
        let tokens: Erc20Token[] = this.$store.getters['Assets/networkErc20Tokens']
        let filt = tokens.filter((t) => {
            if (t.balanceBN.isZero()) return false
            return true
        })
        return filt
    }

    get ercNfts(): ERCNftToken[] {
        let w: WalletType = this.$store.state.activeWallet
        return this.$store.getters['Assets/ERCNft/networkContracts']
    }

    get nativeAssetSymbol(): string {
        return this.$store.getters['Assets/AssetAVA']?.symbol ?? ''
    }

    get nativeAssetName(): string {
        return this.$store.getters['Assets/AssetAVA']?.name ?? ''
    }

    get avaxBalance(): Big {
        let w: WalletType | null = this.$store.state.activeWallet
        if (!w) return Big(0)
        let balBN = w.ethBalance
        return bnToBig(balBN, 18)
    }

    select(token: Erc20Token | 'native') {
        this.$emit('select', token)
        this.close()
    }

    onERCNftSelect(val: iERCNftSelectInput) {
        this.$emit('selectCollectible', val)
        this.close()
    }

    close() {
        this.$refs.modal.close()
    }
}

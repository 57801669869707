








































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import { TokenList } from '@/store/modules/assets/types'

@Component({
    components: {
        Modal,
    },
})
export default class TokenListModal extends Vue {
    $refs!: {
        modal: Modal
    }

    urlIn = ''
    err = ''

    get canAdd() {
        if (this.urlIn.length < 4) {
            return false
        }
        return true
    }

    get lists(): TokenList[] {
        return this.$store.state.Assets.tokenLists
    }

    beforeClose() {
        this.urlIn = ''
        this.err = ''
    }

    async addTokenList() {
        this.err = ''
        this.$store
            .dispatch('Assets/addTokenListUrl', {
                url: this.urlIn,
                readonly: false,
            })
            .then((res) => {
                this.onSuccess()
            })
            .catch((e) => {
                this.onError(e)
            })
    }

    onSuccess() {
        this.urlIn = ''
        this.$store.dispatch('Assets/updateERC20Balances')
    }

    async removeList(list: TokenList) {
        this.$store.dispatch('Assets/removeTokenList', list)
    }

    onError(err: any) {
        this.err = err
    }

    open(): void {
        let modal = this.$refs.modal
        modal.open()
    }
}

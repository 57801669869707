


































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import { MINUTE_MS, ZeroBN } from '@/constants'
import { cleanAvaxBN, formatDuration } from '@/helpers/helper'
import AvaAsset from '@/js/AvaAsset'
import DateForm from './DateForm.vue'
import AvaxInput from '@/components/misc/AvaxInput.vue'
import { WalletType } from '@/js/wallets/types'
import { WalletHelper } from '@/helpers/wallet_helper'

import { BN } from '@c4tplatform/caminojs/dist'
import { DepositOffer } from '@c4tplatform/caminojs/dist/apis/platformvm/interfaces'
import { SignatureError } from '@c4tplatform/caminojs/dist/common'

@Component({
    components: {
        DateForm,
        AvaxInput,
    },
})
export default class DepositForm extends Vue {
    @Prop() offer!: DepositOffer
    @Prop() maxDepositAmount!: BN

    amt: BN = ZeroBN
    endDate: string = ''

    get maxEndDate() {
        return this.offer.end.toNumber() * 1000
    }

    get minDuration() {
        return this.offer.minDuration * 1000
    }

    get maxDuration() {
        return this.offer.maxDuration * 1000 - 15 * MINUTE_MS
    }

    get ava_asset(): AvaAsset | null {
        let ava = this.$store.getters['Assets/AssetAVA']
        return ava
    }

    get nativeAssetSymbol(): string {
        return this.ava_asset?.symbol ?? ''
    }

    get isDepositDisabled(): boolean {
        return (
            this.amt.isZero() ||
            this.amt.gt(this.maxDepositAmount) ||
            this.maxDepositAmount.isZero()
        )
    }

    get duration() {
        const endDate = new Date(this.endDate).getTime()
        return Math.floor((endDate - Date.now()) / 1000)
    }

    async submitDeposit(): Promise<void> {
        const wallet: WalletType = this.$store.state.activeWallet
        try {
            const result = await WalletHelper.buildDepositTx(
                wallet,
                this.offer.id,
                this.duration,
                this.amt
            )
            this.$store.dispatch('updateTransaction', {
                withDeposit: true,
                msgType: 'success',
                msgTitle: 'Transaction',
                msgText: `Deposit Successful (TX: ${result})`,
            })
        } catch (error) {
            if (error instanceof SignatureError) {
                this.$store.dispatch('updateTransaction', {
                    onlyMultisig: true,
                    msgType: 'success',
                    msgTitle: 'Multisignature',
                    msgText: 'Transaction Recorded.',
                })
            } else {
                console.error(error)
                this.$store.dispatch('Notifications/add', {
                    type: 'error',
                    title: 'Deposit Failed',
                    message: error,
                })
                return
            }
        }
        this.cancelDeposit()
    }

    cancelDeposit(): void {
        this.$emit('selectOffer')
    }

    cleanAvaxBN(val: BN): string {
        return cleanAvaxBN(val)
    }

    formatDuration(dur: number): string {
        return formatDuration(dur)
    }

    setEndDate(val: string) {
        this.endDate = val
    }
}























import { Vue, Component, Prop } from 'vue-property-decorator'
import ERCNftToken from '@/js/ERCNftToken'
import ERCNftCard from '@/components/wallet/portfolio/ERCNftCard.vue'
import { ERCNftBalance } from '@/store/modules/assets/modules/types'

@Component({
    components: { ERCNftCard },
})
export default class ERCNftFamilyRow extends Vue {
    @Prop() family!: ERCNftToken

    get walletBalance(): ERCNftBalance[] {
        return this.$store.state.Assets.ERCNft.walletBalance[this.family.data.address] || []
    }

    get hasBalance() {
        return this.walletBalance.length > 0
    }
}




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Tooltip from '@/components/misc/Tooltip.vue'
import ERCNftToken from '@/js/ERCNftToken'
import ERCNftView from '@/components/misc/ERCNftView.vue'
import ERCNftViewModal from '@/components/modals/ERCNftViewModal.vue'
import { ERCNftBalance } from '@/store/modules/assets/modules/types'
@Component({
    components: { ERCNftViewModal, ERCNftView, Tooltip },
})
export default class ERCNftCard extends Vue {
    @Prop() index!: ERCNftBalance
    @Prop() token!: ERCNftToken

    $refs!: {
        view_modal: ERCNftViewModal
    }

    metadata: any = ''
    isRaw = false

    mounted() {
        this.getData()
    }

    get img() {
        let data = this.metadata
        if (!data) return null
        return data.img || data.image || null
    }

    get name() {
        return this.metadata?.name
    }

    get description() {
        return this.metadata?.description
    }

    get ercNftType() {
        return this.token.data.type
    }

    async getData() {
        try {
            let uri = await this.token.getTokenURI(parseInt(this.index.tokenId))
            let res = (await axios.get(uri)).data
            this.metadata = res
        } catch (e) {
            this.metadata = null
        }
    }

    transfer(ev: any) {
        ev.stopPropagation()
        this.$router.push({
            path: '/wallet/transfer',
            query: {
                chain: 'C',
                token: this.token.data.address,
                tokenId: this.index.tokenId,
            },
        })
    }
    expand() {
        this.$refs.view_modal.open()
    }

    toggleRaw() {
        this.isRaw = !this.isRaw
    }
}
